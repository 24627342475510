import { AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {UUID} from 'angular2-uuid';
import {FavoriteService} from '../services/favorite.service';
import {Ride} from '../models/ride';
import {User} from '../models/user';
import {AuthenticationService} from '../services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../services/utility.service';

declare var $: any;
declare var google: any;
declare var accent_fold: any;
declare var swal: any;

@Component({
  selector: 'app-address-field',
  templateUrl: '../templates/address-field.component.html',
  styleUrls: ['../sass/address-field.component.scss'],
  providers: [FavoriteService, AuthenticationService]
})
export class AddressFieldComponent implements AfterViewInit {
  @ViewChild('autoCompleteServiceHolder', {static: false}) autoCompleteServiceHolder;
  @Input() disabled = false;
  @Input() value = '';
  @Input() id = UUID.UUID();
  @Input() index: number;
  @Input() ride: Ride;
  @Input() calculatePrice: any;
  @Input() addressCode: any;
  @Input() address: any = {
    type: ''
  };
  @Input() currentUser: User;
  @Input() componentReference: ComponentRef<AddressFieldComponent>;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  selectedAddressDescription: string;
  autocompleteSessionToken: string;
  favoriteModalId = this.id + '-favorite';
  extraInfoModalId = this.id + '-info';
  error: string;

  public model: any = {};
  data: any = [];
  // public address: any = {uuid:this.id};
  dashboardSettings: any;
  formType = 'default';
  orderType = 'default';

  constructor(private favoriteService: FavoriteService,
              private authenticationService: AuthenticationService,
              private translate: TranslateService,
              private localStorage: CoolLocalStorage,
              private thisElement: ElementRef) {
    this.dashboardSettings = JSON.parse(localStorage.getItem('dashboardSettings'));
    this.formType = this.dashboardSettings.orderSettings.formType || 'default';
    this.orderType = this.dashboardSettings.orderSettings.orderType || 'default';
    translate.use(this.dashboardSettings.corporateSettings.locale);
  }

  ngAfterViewInit() {
    const self = this;
    this.model.businessClientId = this.currentUser.businessClientId;
    self.selectedAddressDescription = self.address.description;

    $(this.thisElement.nativeElement).find('#' + self.id + '-input').autocomplete({
      delay: 1000,
      source(request: any, response: any) {
        // self.ride.addressArray[self.index]
        // self.valueChanged.emit(self.address);
        // console.log(self.index);
        // console.log(self.ride.addressArray);
        delete self.ride.addressArray[self.index].lat;
        delete self.ride.addressArray[self.index].lng;

        let resultsLoaded = false;
        const service = new google.maps.places.AutocompleteService();
        if (!self.autocompleteSessionToken) {
          self.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
        }
        service.getPlacePredictions({
          input: request.term,
          sessionToken: self.autocompleteSessionToken,
          componentRestrictions: {
            country: self.dashboardSettings.corporateSettings.googleMapsCountries
          }
        }, (predictions: any, status: any) => {
          self.data = [];

          if (typeof(self.dashboardSettings.pois.favorites) !== 'undefined') {
            self.data = self.data.concat(self.parsePoi(request.term, self.dashboardSettings.pois.favorites, 'favorite'));
          }

          if (typeof(self.dashboardSettings.pois.airports) !== 'undefined') {
            self.data = self.data.concat(self.parsePoi(request.term, self.dashboardSettings.pois.airports, 'airport'));
          }

          if (typeof(self.dashboardSettings.pois.nsStations) !== 'undefined') {
            self.data = self.data.concat(self.parsePoi(request.term, self.dashboardSettings.pois.nsStations, 'station'));
          }
          // if(typeof(availableCustomPois) != 'undefined'){
          //     poi = poi.concat(parsePoi($('#dep_int'),availableCustomPois,'custom'));
          // }

          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const googleResult = $.map(predictions, (prediction: any) => ({
              type: 'location',
              label: '' + prediction.description + '',
              placeId: prediction.place_id,
              value: prediction.description
            }));

            if (googleResult.length > 0) {
              $.each(googleResult, (i: any, matched: any) => {
                self.data.push({
                  type: 'location',
                  label: '' + matched.label,
                  placeId: matched.placeId,
                  value: matched.value
                });

                response(self.data);
                resultsLoaded = true;
              });
            } else {
              response(self.data);
              resultsLoaded = true;
            }
          } else {
            response(self.data);
            resultsLoaded = true;
          }
        });
      },
      select(event: any, ui: any) {
        console.log('BEFORE CLEAR', self.address);
        // if (self.address.type !== ui.item.type) {
        delete self.address.placeId;
        delete self.address.city;
        delete self.address.postcode;
        delete self.address.synonym;
        delete self.address.type;
        delete self.address.addressCode;
        delete self.address.lat;
        delete self.address.lng;
        delete self.address.poiCode;
        delete self.address.address;
        delete self.address.int_alias;
        delete self.address.locationId;
        delete self.address.rit_address_id;
        // }
        // self.address = {};
        console.log('AFTER CLEAR', self.address);

        self.address.description = ui.item.value;
        self.selectedAddressDescription = ui.item.value;
        self.address.type = ui.item.type;
        self.address.locationId = ui.item.locationId;

        if (ui.item.type === 'location') {
          self.address.placeId = ui.item.placeId;
          self.geocodePlaceId(self.address.placeId, (addressCode) => {
            self.address.lat = addressCode.lat;
            self.address.lng = addressCode.lng;
            self.address.addressCode = JSON.stringify(addressCode);
            self.address.housenumber = addressCode.housenumber;
            if (self.orderType === 'default') {
              self.calculatePrice();
            }
          });
        } else {
          self.address.poiCode = ui.item.poiCode;
          self.address.lat = ui.item.lat;
          self.address.lng = ui.item.lng;
          self.address.housenumber = ui.item.housenumber;
          if (self.orderType === 'default') {
            self.calculatePrice();
          }
        }
        if (typeof self.ride.addressArray[1] !== 'undefined' && typeof self.ride.addressArray[1].type !== 'undefined') {
          $('.tools', 'sortable-addresses').addClass('active');
        }
        self.ride.addressArray[self.index] = self.address;
        console.log(self.index);
        self.valueChanged.emit(self.address);
      }
    })
      .autocomplete('instance')
      ._renderItem = (ul: any, item: any) => $('<li class="' + item.type + '">')
      .append('<div class="icon">' + item.value + '</div>').appendTo(ul);
  }

  openFavoriteModal() {
    $('#' + this.favoriteModalId).prop('checked', true).change();
  }

  openExtraInfoModal() {
    $('#' + this.extraInfoModalId).prop('checked', true).change();
  }

  selectPin() {
    console.log(`[AddressFieldComponent.selectPin]: run`);
  }

  deleteFavorite(id: number) {
    this.favoriteService.deleteFavorite(id, this.currentUser.businessClientId).subscribe();
  }

  onSubmit() {
    const self = this;
    const favorite = {
      ...this.address,
      businessClientId: this.currentUser.businessClientId,
      name: this.model.name,
      description: this.model.description
    };

    this.favoriteService.upsertFavorites(favorite).subscribe(() => {
      $('#' + this.favoriteModalId).prop('checked', false).change();

      /**
       * Update the settings and so add the new favorite
       */
      self.authenticationService.getSettings(self.dashboardSettings.corporateSettings.hash).subscribe(dashboardConfig => {
        self.dashboardSettings = dashboardConfig;
        localStorage.setItem('dashboardSettings', JSON.stringify(dashboardConfig));
      });

      self.translate.get(['favorite_saved_title', 'favorite_saved_text']).subscribe(data => {
        swal({
          title: UtilityService.ucFirst(data.favorite_saved_title),
          text: UtilityService.ucFirst(data.favorite_saved_text),
          type: 'success',
          confirmButtonText: 'Ok'
        });
      });
    });
  }

  parsePoi(input, poiArray, setType) {
    for (let i = 0; i < poiArray.length; i++) {
      poiArray[i].value = poiArray[i].label + ' ' + accent_fold(poiArray[i].label) + ' ' + poiArray[i].poiCode;
    }
    // tslint:disable-next-line:one-variable-per-declaration
    const term = $.ui.autocomplete.escapeRegex(input.toLowerCase()
        .replace('station ', '')
        .replace('station', '')
        .replace('ns ', '')
        .replace('ns', ''))
      // Create two regular expressions, one to find suggestions starting with the user's input:
      , startsWithMatcher = new RegExp('^' + term, 'i')
      , startsWith = $.grep(poiArray, value => startsWithMatcher.test(value.value))
      // ... And another to find suggestions that just contain the user's input:
      , containsMatcher = new RegExp(term, 'i')
      , contains = $.grep(poiArray, value => $.inArray(value, startsWith) < 0 &&
      containsMatcher.test(value.value));

    const poi = [];
    if (startsWith.length > 0) {
      $.each(startsWith, (i, matched) => {
        if (poi.length < 5) {
          poi.push({
            type: setType,
            label: '' + matched.label,
            value: matched.label,
            poiCode: matched.poiCode,
            locationId: matched.locationId,
            lat: matched.lat,
            lng: matched.lng
          });
        }
      });
    }

    if (contains.length > 0) {
      $.each(contains, (i, matched) => {
        if (poi.length < 5) {
          poi.push({
            type: setType,
            label: '' + matched.label + '' + matched.iata,
            value: matched.label,
            poiCode: matched.poiCode,
            locationId: matched.locationId,
            lat: matched.lat,
            lng: matched.lng,
          });
        }
      });
    }

    return poi;
  }

  geocodePlaceId(placeId, next) {
    const self = this;
    if (!placeId) { return next(); }
    if (!self.autocompleteSessionToken) {
      self.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
    }
    const placesService = new google.maps.places.PlacesService(self.autoCompleteServiceHolder.nativeElement);
    return placesService.getDetails({fields: ['address_components', 'geometry.location'], placeId, sessionToken: self.autocompleteSessionToken}, (result, status) => {
      if (status === 'OK') {
        let address: any;
        address = {};
        if (result.address_components) {
          for (let i = 0; i < result.address_components.length; i++) {
            const comp = result.address_components[i];
            if (comp.types[0] === 'postal_code' || comp.types[0] === 'postal_code_prefix') {
              address.postcode = comp.short_name.replace(' ', '');
            }
            if (comp.types[0] === 'locality') {
              address.city = comp.short_name;
            }
            if (comp.types[0] === 'country') {
              address.country = comp.short_name;
            }
            if (comp.types[0] === 'route') {
              address.address = comp.short_name;
            }
            if (comp.types[0] === 'street_number') {
              address.housenumber = comp.short_name.replace(' ', '');
            }
          }

          address.lng = result.geometry.location.lng();
          address.lat = result.geometry.location.lat();
          self.valueChanged.emit(address);
        }
        return next(address);
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  deleteSelf() {
    const self = this;
    this.ride.addressArray.forEach((item, i) => {
      if (item.uuid === self.id) {
        self.ride.addressArray.splice(i, 1);
      }
    });
    // this.componentReference.destroy();
    // this.thisElement.nativeElement.componentReference.hostView.destroy();
    this.thisElement.nativeElement.querySelector('.field').remove();
  }

  validateAddressUpdate(event) {
    const self = this;
    if(self.selectedAddressDescription != event.target.value) {
      delete self.address.placeId;
      delete self.address.city;
      delete self.address.postcode;
      delete self.address.synonym;
      delete self.address.type;
      delete self.address.addressCode;
      delete self.address.lat;
      delete self.address.lng;
      delete self.address.poiCode;
      delete self.address.address;
      delete self.address.int_alias;
      delete self.address.locationId;
      delete self.address.rit_address_id;
    }
  }
}
