import {
  Component,
  OnDestroy,
  AfterViewInit,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ElementRef
} from '@angular/core';
import {User} from '../models/user';
import {RidesService} from '../services/rides.service';
import {Ride} from '../models/ride';
import {Router, ActivatedRoute} from '@angular/router';
import {RouteService} from '../services/route.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../services/utility.service';
import {PassengersFieldComponent} from './passengers-field.component';
import {FieldStatus} from '../models/field-status';
import {VehiclePrice} from '../models/vehicle-price';
import {VehiclePriceLine} from '../models/vehicle-price-line';
import * as moment from 'moment-timezone';

declare var $: any;
declare var sortable: any;
declare var flatpickr: any;
declare var swal: any;
declare var google: any;
declare var Cleave: any;

@Component({
  selector: 'app-ride',
  templateUrl: '../templates/ride.component.html',
  styleUrls: ['../sass/ride.component.scss'],
  providers: [RidesService, RouteService]
})

export class RideComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild(PassengersFieldComponent, {static: false}) passengerField;

  public ride: Ride = new Ride();
  formType = 'default';
  orderType = 'default';
  id: number = undefined;
  currentUser: User;
  dashboardSettings: any;
  submitted = false;
  origin = { lng: 0, lat: 0 };
  destination = { lng: 0, lat: 0 };
  String: any;
  Math: any;
  isDataAvailable = false;
  routeModalId = 'route-modal';
  selectedVehicleTypeKey = 0;
  selectedVehicleType: any;
  vehicleTypes: any;
  currencyChar: string;
  price: number;
  priceFixed: boolean;
  priceObject: any;
  maxPassengers = 10;
  routeSubmitted = false;
  currentTimeDesc = 'Zo snel mogelijk';
  tmpCurrentTimeDesc = '';
  freeFields = [];

  pricingModalId = 'pricing-modal';
  vehiclePricing: VehiclePrice[] = [];
  selectedPrice: VehiclePrice;
  selectedPriceLine: VehiclePriceLine;
  selectedRideType: string;
  departureFromAirport = false;
  orderStateBlocked = false;

  validForm = true;
  formInputs = {
    address: new FieldStatus(),
  };

  returnRideData: any = {
    addressArray: [{}, {}],
    requestedDate: '',
    requestedDateType: '',
    flightNumber: '',
    remark: '',
    requestedDateDay: '',
    currentReturnTimeDesc: '',
    requestedDateTime: '',
  };
  returnRide: Ride = new Ride();

  helpTextAddressId = 'help-text-address';
  helpTextTimesId = 'help-text-times';
  helpTextPassengersId = 'help-text-passengers';

  // singleRide, connected, returnRide
  editMode = 'singleRide';
  blockConnectedEdit = ['canceled', 'cancelled', 'completed', 'client_pickup', 'active', 'arrived'];

  translationKeys = [
    'asap_text',
    'at',
    'booking_outside_hours_title',
    'booking_outside_hours_text',
    'ride_edited_title',
    'ride_edited_text',
    'ride_booked_title',
    'ride_booked_text',
    'ride_not_edited_title',
    'ride_not_booked_title',
    'return_ride_booked_title',
    'return_ride_booked_text',
    'return_ride_edited_title',
    'return_ride_edited_text',
    'route_saved_title',
    'route_saved_text',
    'housenumber_mandatory'
  ];
  translations: any;

  submissionDisabled = false;

  constructor(private router: Router,
              private localStorage: CoolLocalStorage,
              private routeService: RouteService,
              private ridesService: RidesService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private thisElement: ElementRef) {
    this.dashboardSettings = JSON.parse(localStorage.getItem('dashboardSettings'));
    this.formType = this.dashboardSettings.orderSettings.formType || 'default';
    this.orderType = this.dashboardSettings.orderSettings.orderType || 'default';
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.ride.businessClientId = this.currentUser.businessClientId;
    this.returnRide.businessClientId = this.currentUser.businessClientId;

    this.calculatePrice = this.calculatePrice.bind(this);

    const self = this;
    const params: any = this.activatedRoute.snapshot.params;

    this.Math = Math;
    this.String = String;
    this.id = params.id;
    this.currencyChar = self.dashboardSettings.companySettings.decoded_currency_char;

    this.translate.get(this.translationKeys).subscribe(translations => {
      this.translations = translations;
      /**
       * We have to initialize these values for the googlemaps waypoint directives
       */
      this.origin.lat = this.currentUser.department.lat;
      this.origin.lng = this.currentUser.department.lng;
      this.ride.addressArray = [];

      this.ride.requestedDateType = 'pickup';
      if (this.dashboardSettings.orderSettings.minTime === 0) {
        this.currentTimeDesc = translations.asap_text;
        this.ride.requestedDate = 'now';
      } else {
        self.currentTimeDesc = moment().tz('Europe/Amsterdam').format(`DD-MM-YYYY HH:mm`);
        self.ride.requestedDate = new Date().toISOString();
      }

      this.ride.paymentMethod = (
        typeof(this.dashboardSettings.orderSettings.paymentMethods[0]) !== 'undefined' ?
          this.dashboardSettings.orderSettings.paymentMethods[0] :
          'contant');

      this.vehicleTypes = (this.dashboardSettings.orderSettings.carTypes ? this.dashboardSettings.orderSettings.carTypes : []);

      this.selectedVehicleTypeKey = 0;
      this.ride.passengerCount = 1;
      this.ride.vehicleType = (
        typeof(this.dashboardSettings.orderSettings.carTypes[0]) !== 'undefined' ?
          this.dashboardSettings.orderSettings.carTypes[0].car_type_id : '1');
      this.ride.departmentId = this.currentUser.department.id;

      /**
       * Prepare object binding for the dynamic freeRideField array in ride
       */
      if (this.dashboardSettings.orderSettings.freeRideFields) {
        this.ride.freeFields = [];
        $(this.dashboardSettings.orderSettings.freeRideFields).each((i, item) => {
          /**
           * Select the first value if dropdown
           */
          const required = ((
            (item.mandatory === 'mandatory') ||
            (item.mandatory === 'onaccount_mandatory' && self.ride.paymentMethod === 'oprekening')
          ) && !self.id);

          if (item.type === 'dropdown') {
            if (required) {
              self.ride.freeFields[i] = '';
              self.formInputs[item.field_name.toLowerCase().replace(/ /g, '-')] = new FieldStatus(false, 'please_select_dropdown_value');
            } else {
              self.ride.freeFields[i] = item.options[0].rit_field_meta_option_id;
              self.formInputs[item.field_name.toLowerCase().replace(/ /g, '-')] = new FieldStatus(true);
            }
          } else {
            self.ride.freeFields[i] = '';
          }
        });
      }

      if (this.id !== undefined) {
        this.ride.id = this.id;
        /**
         * Edit ride
         */
        this.ridesService.getRide(this.id).subscribe(ride => {
          console.log(`[RideComponent.ride]: ride`, ride);
          this.isDataAvailable = true;
          this.ride = ride;

          if (ride.dateType === 'airportArrival') {
            this.ride.requestedDateType = ride.dateType;
            this.currentTimeDesc = ride.dateTypeDate + ' ' + ride.dateTypeTime;
          } else if (ride.dateType === 'airportDeparture') {
            this.ride.requestedDateType = ride.dateType;
            this.currentTimeDesc = ride.dateTypeDate + ' ' + ride.dateTypeTime;
          } else {
            this.currentTimeDesc = ride.requestedDate + ' ' + ride.requestedDateTime;
          }

          // Determine the edit mode for this ride
          if (ride.connectedRideId) {
            if (ride.firstInReturn) {
              this.editMode = 'connected';
              this.blockOrderState(true);
            } else if (!ride.firstInReturn && this.blockConnectedEdit.includes(ride.firstInReturnStatus)) {
              this.editMode = 'returnRide';
            }
          }

          if (ride.returnTrip) {
            this.setReturnRideData();
            // const splitDateStr = ride.returnTrip.dateTypeTimestamp.split(' ');
            if(!ride.returnTrip.dateTypeTimestamp) {
              ride.returnTrip.dateTypeDate = ride.returnTrip.requestedDate;
              ride.returnTrip.dateTypeTime = ride.returnTrip.requestedDateTime;
            }
            this.returnRideData = {
              ...this.returnRideData,
              addressArray: ride.returnTrip.addressArray,
              requestedDate: ride.returnTrip.requestedDate,
              requestedDateType: ride.returnTrip.requestedDateType,
              flightNumber: ride.returnTrip.flightNumber,
              remark: ride.returnTrip.remark,
              requestedDateDay: ride.returnTrip.dateTypeDate,
              currentReturnTimeDesc: ride.returnTrip.dateTypeDate + ' ' + ride.returnTrip.dateTypeTime,
              requestedDateTime: ride.returnTrip.dateTypeTime,
            };
            const priceLine: VehiclePriceLine = {
              amount: ride.amount * 2,
              decimalAmount: (ride.amount * 2) / 100,
              estimated: false,
              available: true
            };
            const price: VehiclePrice = {
              vehicleType: ride.vehicleTypeName,
              vehicleTypeId: ride.vehicleType,
              name: ride.vehicleTypeName,
              maxPassengers: 0,
              maxLuggage: 0,
              single: priceLine,
              return: priceLine,
            };
            this.selectPrice('return', price);
            console.log('this.returnRideData:', this.returnRideData);
          }
          console.log('EDIT MODE:', this.editMode);

          // console.log('currentTimeDesc 1:', this.currentTimeDesc);
          this.ride.requestedDateDay = this.ride.requestedDate;
          if (this.dashboardSettings.orderSettings.freeRideFields && typeof(this.ride.freeFields) === 'undefined') {
            this.ride.freeFields = [];
            $(this.dashboardSettings.orderSettings.freeRideFields).each((i, item) => {
              if (item.type === 'dropdown') {
                self.ride.freeFields[i] = item.options[0].rit_field_meta_option_id;
              } else {
                self.ride.freeFields[i] = '';
              }
            });
          }
          else {
            const fieldValues = [];
            this.ride.freeFields = [];
            if (this.ride.freeFieldsConfig) {
              this.ride.freeFieldsConfig.forEach((item, i) => {
                if (item.type === 'dropdown') {
                  fieldValues.push(item.rit_field_meta_option_id);
                } else {
                  fieldValues.push(item.currentValue);
                }
              });
              this.ride.freeFields = fieldValues;
            }
          }
        });
      } else {
        if (params.routeId) {
          this.ride.routeId = params.routeId;
          /**
           * New ride from route
           */
          this.routeService.getRoute(this.currentUser.businessClientId, params.routeId).subscribe(route => {
            this.ride = JSON.parse(route);
            this.ride.routeId = params.routeId;
            this.isDataAvailable = true;
            if (this.dashboardSettings.orderSettings.freeRideFields && typeof(this.ride.freeFields) === 'undefined') {
              this.ride.freeFields = [];
              $(this.dashboardSettings.orderSettings.freeRideFields).each((i, item) => {
                if (item.type === 'dropdown') {
                  self.ride.freeFields[i] = item.options[0].rit_field_meta_option_id;
                } else {
                  self.ride.freeFields[i] = '';
                }
              });
            } else {
              const fieldValues = [];
              this.ride.freeFields = [];
              this.ride.freeFieldsConfig.forEach((item, i) => {
                if (item.type === 'dropdown') {
                  fieldValues.push(item.rit_field_meta_option_id);
                } else {
                  fieldValues.push(item.currentValue);
                }
              });
              this.ride.freeFields = fieldValues;
            }
          });
        } else {
          /**
           * New ride
           */
          // this.ride.id = 123123;
          this.ride.addressArray.push({});
          this.isDataAvailable = true;
        }
      }
    });
  }

  setNumberSelectDefaults() {
    if (this.dashboardSettings.orderSettings.freeRideFields) {
      this.dashboardSettings.orderSettings.freeRideFields.forEach((item, i) => {
        if (item.type === 'numberSelect') {
          this.ride.freeFields[i] = item.min;
        }
      });
    }
  }

  setCheckboxDefaults() {
    if (this.dashboardSettings.orderSettings.freeRideFields) {
      this.dashboardSettings.orderSettings.freeRideFields.forEach((item, i) => {
        if (item.type === 'checkbox' && item.default_value !== '') {
          this.ride.freeFields[i] = item.default_value;
        }
      });
    }
  }

  public calculatePrice() {
    const self = this;

    if (this.dashboardSettings.orderSettings.calcMethod === 'none') {
      return false;
    }

    if (this.dashboardSettings.orderSettings.freeRideFields.length !== this.ride.freeFields.length) {
      /**
       * Correct the freeFields
       * Get last this.dashboardSettings.orderSettings.freeRideFields.length of freeFields and put that in the the freeFields
       */
      if (this.ride.freeFields.length > 0) {
        this.ride.freeFields = this.ride.freeFields.slice((this.ride.freeFields.length-this.dashboardSettings.orderSettings.freeRideFields.length), this.ride.freeFields.length);
      }
    }

    self.ridesService.calculateRidePrice(this.ride, this.dashboardSettings.corporateSettings.hash).subscribe((price) => {
      const depField = $(`#${self.ride.addressArray[0].uuid}`);
      const arrIndex = self.ride.addressArray.length - 1;
      const arrField = $(`#${self.ride.addressArray[arrIndex].uuid}`);

      arrField.removeClass('error required');
      depField.removeClass('error required');

      if (typeof price.error !== 'undefined') {
        console.error(`[RideComponent.calculateRidePrice]: error`, price);
        if (price.error === 'arr_address_error') {
          arrField.addClass('error required');
        } else if (price.error === 'dep_address_error') {
          depField.addClass('error required');
        }
      } else {
        this.priceObject = price;
        if (price.amount > 0 ) {
          this.price = price.amount / 100;
          this.priceFixed = true;
        } else if (price.routePrice && price.routePrice.fullPrice > 0) {
          this.price = price.routePrice.fullPrice / 100;
          this.priceFixed = false;
        } else {
          this.price = 0;
          this.priceFixed = false;
        }
      }
    });

    console.log('RIDE', this.ride);
  }

  public calculateReturnPrice() {
    const self = this;

    if (this.dashboardSettings.orderSettings.calcMethod === 'none') {
      return false;
    }

    self.ridesService.calculateRidePrice(this.returnRide, this.dashboardSettings.corporateSettings.hash).subscribe((price) => {
      const depField = $(`#${self.ride.addressArray[0].uuid}`);
      const arrIndex = self.ride.addressArray.length - 1;
      const arrField = $(`#${self.ride.addressArray[arrIndex].uuid}`);

      arrField.removeClass('error required');
      depField.removeClass('error required');

      if (typeof price.error !== 'undefined') {
        console.error(`[RideComponent.calculateRidePrice]: error`, price);
        if (price.error === 'arr_address_error') {
          arrField.addClass('error required');
        } else if (price.error === 'dep_address_error') {
          depField.addClass('error required');
        }
      } else {
        this.priceObject = price;
        if (price.amount > 0 ) {
          this.price = price.amount / 100;
          this.priceFixed = true;
        } else if (price.routePrice && price.routePrice.fullPrice > 0) {
          this.price = price.routePrice.fullPrice / 100;
          this.priceFixed = false;
        } else {
          this.price = 0;
          this.priceFixed = false;
        }
      }
    });

    console.log('RIDE', this.returnRide);
  }

  onSubmit() {
    const self = this;
    const errors = this.preValidateForm();
    this.submitted = true;
    this.ride.businessClientId = this.currentUser.businessClientId;
    if (this.vehicleTypes.length > 0) {
      this.ride.carTypeId = this.selectedVehicleType;
    }
    if (this.priceFixed) {
      this.ride.amount = this.price * 100;
    }

    if (self.ride.requestedDate.indexOf('now') === 0) {
      self.ride.requestedDate = 'now';
    } else {
      if (self.ride.requestedDateDay && self.ride.requestedDateTime){
        self.ride.requestedDate = self.ride.requestedDateDay + ' ' + self.ride.requestedDateTime;
      }
    }

    /**
     * Allow submission if there are no errors
     */
    if (!errors) {
      const validateRoster = this.dashboardSettings.orderSettings.validateRoster;
      if (!validateRoster) {
        if (this.selectedRideType === 'return') {
          this.upsertReturnRides();
        } else {
          this.upsertRide();
        }
      } else {
        self.ride.requestedDate = self.ride.requestedDateDay + ' ' + self.ride.requestedDateTime;
        this.ridesService.validateOrderTime(
          self.ride.requestedDate,
          this.dashboardSettings.companySettings.companyId,
          this.dashboardSettings.corporateSettings.hash
        ).subscribe(valid => {
          // console.log(`[RideComponent.validateOrdertime]: valid`, valid);
          if (!valid.result) {
            // console.log(`[RideComponent.validateOrdertime]: No valid time`);
            swal({
              title: UtilityService.ucFirst(self.translations.booking_outside_hours_title),
              text: UtilityService.ucFirst(self.translations.booking_outside_hours_text),
              type: 'warning',
              confirmButtonText: 'Ok'
            });
          } else {
            // console.log(`[RideComponent.validateOrdertime]: Valid time - book ride`);
            if (this.selectedRideType === 'return') {
              this.upsertReturnRides();
            } else {
              this.upsertRide();
            }
          }
        }, (error) => {
          console.log(`[RideComponent.validateOrderTime]: error`, error);
        });
      }
    } else {
      console.log('errors', 'Not submitting');
    }
  }

  changeVehicleType(key) {
    if (this.vehicleTypes.length > 0) {
      this.selectedVehicleTypeKey = key;
      this.selectedVehicleType = this.vehicleTypes[key];
      this.maxPassengers = this.selectedVehicleType.max_passengers;
      this.passengerField.setMaxPassengers(this.maxPassengers);
      this.ride.vehicleType = this.selectedVehicleType.car_type_id;
    }
  }

  upsertRide() {
    this.submissionDisabled = true;
    const self = this;
    this.ridesService.upsertRide(this.ride).subscribe(ride => {
      if (self.ride.id) {
        swal({
            title: UtilityService.ucFirst(self.translations.ride_edited_title),
            text: UtilityService.ucFirst(self.translations.ride_edited_text),
            type: 'success',
            confirmButtonText: 'Ok'
          },
          () => {
            self.router.navigate([`/ride/${self.ride.id}`]);
          });
      } else {
        swal({
            title: UtilityService.ucFirst(self.translations.ride_booked_title),
            text: UtilityService.ucFirst(self.translations.ride_booked_text).formatUnicorn(ride.id),
            type: 'success',
            html: true,
            confirmButtonText: 'Ok'
          },
          () => {
            self.router.navigate(['/dashboard']);
          });
      }
    }, () => {
      self.submissionDisabled = false;
      if (self.ride.id) {
        swal({
          title: UtilityService.ucFirst(self.translations.ride_not_edited_title),
          text: 'error',
          type: 'warning',
          confirmButtonText: 'Ok'
        });
      } else {
        swal({
          title: UtilityService.ucFirst(self.translations.ride_not_booked_title),
          text: 'error',
          type: 'warning',
          confirmButtonText: 'Ok'
        });
      }
    });
  }

  upsertReturnRides() {
    this.submissionDisabled = true;
    const self = this;

    const ride = {...this.ride};
    if (this.ride.returnTrip) {
      this.returnRide = {...this.ride.returnTrip};
    } else {
      this.returnRide = {...ride};
    }
    delete ride.returnTrip;

    if(this.selectedPriceLine) {
      ride.amount = Math.round(this.selectedPriceLine.amount / 2);
      ride.decAmount = Math.round((this.selectedPriceLine.decimalAmount * 100) / 2);
      this.returnRide.amount = Math.round(this.selectedPriceLine.amount / 2);
      this.returnRide.decAmount = Math.round((this.selectedPriceLine.decimalAmount * 100) / 2);
    } else {
      this.returnRide.amount = ride.amount;
      this.returnRide.decAmount = ride.decAmount;
    }

    this.returnRide.requestedDate = this.returnRideData.requestedDate;
    this.returnRide.requestedDateType = this.returnRideData.requestedDateType;
    this.returnRide.requestedDateDay = this.returnRideData.requestedDateDay;
    this.returnRide.requestedDateTime = this.returnRideData.requestedDateTime;
    this.returnRide.flightNumber = this.returnRideData.flightNumber;
    this.returnRide.addressArray = this.returnRideData.addressArray;

    if (this.returnRide.requestedDate.indexOf('now') === 0) {
      this.returnRide.requestedDate = 'now';
    } else {
      this.returnRide.requestedDate = this.returnRide.requestedDateDay + ' ' + this.returnRide.requestedDateTime;
    }

    this.returnRide.dateTypeDate = this.returnRide.requestedDateDay;
    this.returnRide.dateTypeTime = this.returnRide.requestedDateTime;
    this.returnRide.dateTypeTimestamp = this.returnRide.dateTypeDate + ' ' + this.returnRide.dateTypeTime;

    if (this.editMode === 'connected') {
      this.returnRide.freeFields = ride.freeFields;
      this.returnRide.remark = ride.remark;
      this.returnRide.passengerArray = ride.passengerArray;
      this.returnRide.passengerCount = ride.passengerCount;
      this.returnRide.passengers = ride.passengers;
    }

    console.log(`[RideComponent.upsertReturnRides]: ride`, ride);
    console.log(`[RideComponent.upsertReturnRides]: returnRide`, this.returnRide);

    const postBody = {
      trip: ride,
      returnTrip: this.returnRide
    };

    console.log(`[RideComponent.upsertReturnRides]:`, postBody);

    this.ridesService.upsertReturnRide(postBody).subscribe(rideIds => {
      if (self.ride.id) {
        swal({
            title: UtilityService.ucFirst(self.translations.return_ride_edited_title),
            text: UtilityService.ucFirst(self.translations.return_ride_edited_text).formatUnicorn(rideIds.tripId, rideIds.returnTripId),
            type: 'success',
            html: true,
            confirmButtonText: 'Ok'
          },
          () => {
            self.router.navigate([`/ride/${self.ride.id}`]);
          });
      } else {
        swal({
            title: UtilityService.ucFirst(self.translations.return_ride_booked_title),
            text: UtilityService.ucFirst(self.translations.return_ride_booked_text).formatUnicorn(rideIds.tripId, rideIds.returnTripId),
            type: 'success',
            html: true,
            confirmButtonText: 'Ok'
          },
          () => {
            self.router.navigate(['/dashboard']);
          });
      }
    }, () => {
      self.submissionDisabled = true;
      if (self.ride.id) {
        swal({
          title: UtilityService.ucFirst(self.translations.ride_not_edited_title),
          text: 'error',
          type: 'warning',
          confirmButtonText: 'Ok'
        });
      } else {
        swal({
          title: UtilityService.ucFirst(self.translations.ride_not_booked_title),
          text: 'error',
          type: 'warning',
          confirmButtonText: 'Ok'
        });
      }
    });
  }

  onSubmitRoute() {
    this.routeSubmitted = true;

    this.routeService.upsertRoutes(this.currentUser.businessClientId, this.ride).subscribe(() => {
      $('#' + this.routeModalId).prop('checked', false).change();

      swal({
        title: UtilityService.ucFirst(this.translations.route_saved_title),
        text: UtilityService.ucFirst(this.translations.route_saved_text),
        type: 'success',
        confirmButtonText: 'Ok'
      }, () => {});
    });
  }

  onMarkerDragEnd(event: any, i) {
    const geocoder = new google.maps.Geocoder();
    const self = this;
    geocoder.geocode({latLng: {lat: event.coords.lat, lng: event.coords.lng}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          self.ride.addressArray[i].lat = event.coords.lat;
          self.ride.addressArray[i].lng = event.coords.lng;
          self.ride.addressArray[i].placeId = results[0].place_id;
          self.ride.addressArray[i].description = results[0].formatted_address;
          self.ride.addressArray[i].type = 'location';
          self.geocodePlaceId(results[0].place_id, (addressCode) => {
            self.ride.addressArray[i].addressCode = addressCode;
            if (this.orderType === 'default') {
              self.calculatePrice();
            }
          });
        }
      }
    });
  }

  geocodePlaceId(placeId, next) {
    if (!placeId) { return next(); }
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode({placeId}, (results, status) => {
      if (status === 'OK') {
        let address: any;
        address = {};
        if (results[0].address_components) {
          for (let i = 0; i < results[0].address_components.length; i++) {
            const comp = results[0].address_components[i];
            if (comp.types[0] === 'postal_code' || comp.types[0] === 'postal_code_prefix') {
              address.postcode = comp.short_name.replace(' ', '');
            }
            if (comp.types[0] === 'locality') {
              address.city = comp.short_name;
            }
            if (comp.types[0] === 'country') {
              address.country = comp.short_name;
            }
            if (comp.types[0] === 'route') {
              address.address = comp.short_name;
            }
            if (comp.types[0] === 'street_number') {
              address.housenumber = comp.short_name.replace(' ', '');
            }
          }

          address.lng = results[0].geometry.location.lng();
          address.lat = results[0].geometry.location.lat();
        }
        return next(JSON.stringify(address));
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  onMapClicked(event: any) {
    const geocoder = new google.maps.Geocoder();
    const self = this;
    if (typeof self.ride.addressArray[1].lat === 'undefined') {
      geocoder.geocode({latLng: {lat: event.coords.lat, lng: event.coords.lng}}, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            self.ride.addressArray[1].lat = event.coords.lat;
            self.ride.addressArray[1].lng = event.coords.lng;
            self.ride.addressArray[1].placeId = results[0].place_id;
            self.ride.addressArray[1].description = results[0].formatted_address;
            self.ride.addressArray[1].type = 'location';
            if (this.orderType === 'default') {
              self.calculatePrice();
            }
          }
        }
      });
    }
  }

  ngOnInit() {
    const baseOffset = 65;
    const el = $('.search');
    const totalOffset = ( baseOffset + (el.is(':visible') ? (el.innerHeight() + $('.table').innerHeight()) : 0) );
    $('#map').css('height', ($(window).innerHeight() - totalOffset) + 'px');

    if (!this.id) {
      this.setNumberSelectDefaults();
      this.setCheckboxDefaults();
    }
  }

  ngOnDestroy() {
    $('#map').html('');
  }

  openRouteModal() {
    $('#' + this.routeModalId).prop('checked', true).change();
  }

  startReturnTrip() {
    const errors = this.preValidateForm();
    if (!errors) {
      this.selectedRideType = 'return';
      this.blockOrderState(true);
      this.setReturnRideData();
    }
  }

  openPricingModal() {
    const errors = this.preValidateForm();
    if (!errors) {
      const data = {
        rideId: this.ride.id,
        addressArray: this.ride.addressArray,
        requestedDate: this.ride.requestedDate,
        paymentMethod: this.ride.paymentMethod,
        passengerCount: this.ride.passengerCount,
        departmentId: this.currentUser.department.id,
        distance: this.ride.distance,
        duration: this.ride.duration,
        freeFields: this.ride.freeFields
      };

      this.ridesService.calculateRideQuotation(data, this.dashboardSettings.corporateSettings.hash).subscribe((pricing: VehiclePrice[]) => {
        this.vehiclePricing = pricing;
        $('#' + this.pricingModalId).prop('checked', true).change();

        if (pricing.length > 0) {
        }

      }, (error) => {
        console.error(error);
      });
    }
  }

  toggleHelpText(metaId: string) {
    // console.log(`toggleHelpText(${metaId})`);
    $('#' + 'help-text-' + metaId).prop('checked', true).change();
  }

  ngAfterViewInit() {
    const self = this;
    const moneyFields = $('.money-input');

    if (typeof(google) === 'undefined') {
      setTimeout(() => { self.ngAfterViewInit(); }, 100);
      return false;
    }

    if (typeof($('.vehicle-type').html()) !== 'undefined') {
      setTimeout(() => {
        this.changeVehicleType(0);
      }, 500);
    }

    /**
     * Prepare object binding for the dynamic freeRideField array in ride
     */
    if (this.dashboardSettings.orderSettings.freeRideFields) {
      $(this.dashboardSettings.orderSettings.freeRideFields).each((i, item) => {
        if(item.autoComplete) {
          this.initFieldAutocomplete(item, i);
        }
      });
    }

    this.ridesService.getMinRequestDate(
      this.dashboardSettings.companySettings.companyId,
      this.dashboardSettings.corporateSettings.hash
    ).subscribe(minOrderDate => {
      minOrderDate = new Date(minOrderDate.time);
      const nowDate = moment(minOrderDate).tz('Europe/Amsterdam').format(`DD-MM-YYYY`);
      const nowTime = moment(minOrderDate).tz('Europe/Amsterdam').format(`HH:mm`);

      if (!self.ride.dateType) {
        self.tmpCurrentTimeDesc = this.formType === 'airport' ? `${nowDate} ${self.translations.at} ${nowTime}` : self.currentTimeDesc;
        self.currentTimeDesc = new Date().toISOString();
        self.ride.requestedDate = minOrderDate.toISOString();
      } else {
        self.tmpCurrentTimeDesc = moment(new Date(self.ride.dateTypeTimestamp)).tz('Europe/Amsterdam').format(`DD-MM-YYYY HH:mm`);
      }

      flatpickr.l10ns.default.firstDayOfWeek = 1;
      setTimeout(function(){
        console.log(minOrderDate);
          const returnDateInstance = flatpickr('#requestedReturnDate', {
            minDate: minOrderDate,
            weekNumbers: true,
            enableTime: true,
            time_24hr: true,
            dateFormat: 'd-m-Y H:i',
            disableMobile: true,
            onChange(selectedDates, dateStr) {
              console.log('Onchange: ', dateStr);
              const splitDateStr = dateStr.split(' ');
              self.returnRideData.requestedDate = '';
              self.returnRideData.requestedDateDay = splitDateStr[0];
              self.returnRideData.requestedDateTime = splitDateStr[splitDateStr.length - 1];
              self.returnRideData.currentReturnTimeDesc = splitDateStr[0] + ' ' + self.translations.at + ' ' + splitDateStr[splitDateStr.length - 1];
              if (this.orderType === 'default') {
                self.calculatePrice();
              }
              return false;
            }
          });
          self.returnRideData.currentReturnTimeDesc = self.tmpCurrentTimeDesc;

          flatpickr('#requestedDate', {
            minDate: minOrderDate,
            weekNumbers: true,
            enableTime: true,
            time_24hr: true,
            dateFormat: 'd-m-Y H:i',
            disableMobile: true,
          onChange(selectedDates, dateStr) {
            // console.log('Onchange: ', dateStr);
            // console.log('selectedDates: ', selectedDates);
            const splitDateStr = dateStr.split(' ');
            self.ride.requestedDate = new Date(selectedDates[0]).toISOString();
            self.ride.requestedDateDay = splitDateStr[0];
            self.ride.requestedDateTime = splitDateStr[splitDateStr.length - 1];
            self.currentTimeDesc = splitDateStr[0] + ' ' + self.translations.at + ' ' + splitDateStr[splitDateStr.length - 1];

            if(!self.ride || !self.ride.id) {
              returnDateInstance.set('minDate', selectedDates[0]);
              returnDateInstance.set('defaultDate', selectedDates[0]);

              returnDateInstance.setDate(selectedDates[0]);

              self.returnRideData.requestedDate = '';
              self.returnRideData.requestedDateDay = splitDateStr[0];
              self.returnRideData.requestedDateTime = splitDateStr[splitDateStr.length - 1];
              self.returnRideData.currentReturnTimeDesc = splitDateStr[0] + ' ' + self.translations.at + ' ' + splitDateStr[splitDateStr.length - 1];
            }

            if (this.orderType === 'default') {
              self.calculatePrice();
            }
            return false;
          }
        });
      }, 500);
      self.currentTimeDesc = self.tmpCurrentTimeDesc;
    });

    $('select', '.field.select').select2({
      minimumResultsForSearch: Infinity
    });

    $('select', '.field.select.search').select2({});

    $('select', '.payment-method').on('select2:select', evt => {
      // console.log('select2:select payment method');
      this.onImportantValueChange(null);
      const field = $('#' + evt.target.id);
      self.ride[evt.target.id] = field.val();
      if (this.orderType === 'default') {
        self.calculatePrice();
      }
    });

    $('select', '.passengerCount').on('select2:select', evt => {
      if (this.orderType === 'default') {
        self.calculatePrice();
      }
    });

    $('select', '.vehicle-type').on('select2:select', evt => {
      // console.log('select2:select vehicle type');
      this.onImportantValueChange(null);
      const field = $('#' + evt.target.id);
      self.changeVehicleType(field.val());
      if (this.orderType === 'default') {
        self.calculatePrice();
      }
    });

    $('select', '.free-fields').on('select2:select', evt => {
      // console.log('select2:select free field');
      // this.onImportantValueChange(null);
      const field = $('#' + evt.target.id);
      const key = field.data('key');
      const name = field.data('field-name');
      self.ride.freeFields[key] = field.val();
      this.customSelectChanged(field.val(), name);
      if (this.orderType === 'default') {
        self.calculatePrice();
      }
    });

    const numeralDecimalMark = (self.dashboardSettings.companySettings.number_format === 'komma' ? ',' : '.');
    const delimiter = (self.dashboardSettings.companySettings.number_format !== 'komma' ? ',' : '.');

    if (moneyFields.length > 0) {
      const moneyInputs = new Cleave('.money-input', {
        prefix: `${self.currencyChar} `,
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark,
        delimiter,
        numeralThousandsGroupStyle: 'thousand'
      });
    }

    setTimeout(() => {
      resizeMap();
      $('select').trigger('change');
      $('.interactive').trigger('keyup').trigger('blur');
    }, 500);

    $(window).on('resize', () => {
      resizeMap();
    });

    function resizeMap() {
      const baseOffset = 65;
      const totalOffset = ( baseOffset + $('.breadcrumb').innerHeight() );
      $('#map').css('height', ($(window).innerHeight() - totalOffset) + 'px');
      $('.complaint', '.right').css('height', ($(window).innerHeight() - totalOffset) + 'px');
      $('.rating', '.right').css('height', ($(window).innerHeight() - totalOffset) + 'px');
      $('.left').css('height', ($(window).innerHeight() - totalOffset) + 'px');
    }
  }

  padNumber(str, max) {
    str = str.toString();
    return str.length < max ? this.padNumber('0' + str, max) : str;
  }

  setReturnRideData() {
    console.log(this.returnRideData);
    // Set address array
    let addressArray = this.ride.addressArray.slice();
    addressArray = addressArray.reverse();
    this.returnRideData.addressArray = addressArray;

    // Set requested date type
    if (this.formType === 'airport' && this.departureFromAirport) {
      this.returnRideData.requestedDateType = 'airportArrival';
    } else if (this.formType === 'airport' && !this.departureFromAirport) {
      this.returnRideData.requestedDateType = 'airportDeparture';
    } else {
      this.returnRideData.requestedDateType = 'pickup';
    }
  }

  onAddressChanged(status: any) {
    const $depField = $(`#${this.ride.addressArray[0].uuid}`);
    const arrIndex = this.ride.addressArray.length - 1;
    const $arrField = $(`#${this.ride.addressArray[arrIndex].uuid}`);

    this.departureFromAirport = (
      this.ride.addressArray[0].type === 'airport' ||
      (this.ride.addressArray[0].poiCode ? this.ride.addressArray[0].poiCode : '')
        .startsWith('AIR'));

    if (this.formType === 'airport' && this.departureFromAirport) {
      this.ride.requestedDateType = 'airportDeparture';
    } else if (this.formType === 'airport' && !this.departureFromAirport) {
      this.ride.requestedDateType = 'airportArrival';
    } else {
      this.ride.requestedDateType = 'pickup';
    }

    this.setReturnRideData();

    $depField.removeClass('error required');
    $arrField.removeClass('error required');

    this.formInputs.address = status;
    if (!status.valid) {
      $depField.addClass('error required');
      $arrField.addClass('error required');
    }

    // console.log(`[RideComponent.onAddressChanged]: this.returnRideData:`, this.returnRideData);

    if (this.orderType === 'default') {
      this.calculatePrice();
    }

    this.validateForm();
  }

  preValidateForm() {
    let errors = false;
    /**
     * Validate the ride
     */
    $('.field').removeClass('error');

    /**
     * Check address fields
     */

    this.ride.addressArray.forEach((item, key) => {
      if (key < (this.ride.addressArray.length - 1)) { // Check every address except the last one
        if (typeof item.lat === 'undefined' || typeof item.lng === 'undefined') {
          $('#' + item.uuid).addClass('error required');
          errors = true;
        }

        // tslint:disable-next-line:max-line-length
        if (this.dashboardSettings.orderSettings.mandatoryHouseNumber && typeof item.housenumber === 'undefined' && item.type !== 'airport') {
          $('#' + item.uuid).addClass('error required');
          $('#' + item.uuid + '-field-error').html(this.translations['housenumber_mandatory']);
          $('#' + item.uuid + '-field-error').slideDown(300);
          errors = true;
        } else {
          $('#' + item.uuid + '-field-error').hide();
        }
      }

      if (this.dashboardSettings.orderSettings.mandatoryDestinationAddress && key === (this.ride.addressArray.length - 1)) {
        if (typeof item.lat === 'undefined' || typeof item.lng === 'undefined' || typeof item.description === 'undefined') {
          $('#' + item.uuid).addClass('error required');
          errors = true;
        }
        if (this.dashboardSettings.orderSettings.mandatoryHousenumber && typeof item.housenumber === 'undefined') {
          $('#' + item.uuid).addClass('error required');
          errors = true;
        }
      }
    });

    const firstNameField = $('.field.firstName');
    const passengersField = $('.field.passengers');
    const phoneField = $('.field.phone');
    const flightNumberField = $('#flightNumber');
    const dateField = $('#requestedReturnDate');

    if (this.dashboardSettings.orderSettings.mandatoryName) {
      if ($('input', firstNameField).val() === '') {
        // console.log(`[RideComponent.onSubmit]: firstNameField.val()`, $('input', firstNameField).val());
        passengersField.addClass('error required');
        firstNameField.addClass('error required');
        errors = true;
      }
    }

    if (this.dashboardSettings.orderSettings.mandatoryPhoneNumber) {
      if ($('input', phoneField).val() === '') {
        // console.log(`[RideComponent.onSubmit]: phoneField.val()`, $('input', phoneField).val());
        passengersField.addClass('error required');
        phoneField.addClass('error required');
        errors = true;
      }
    }

    if (this.dashboardSettings.orderSettings.flightNumber === 'mandatory') {
      if (this.ride.flightNumber === '' || typeof this.ride.flightNumber === 'undefined') {
        flightNumberField.addClass('error required');
        errors = true;
      }
    }

    const freeFields = this.dashboardSettings.orderSettings.freeRideFields;

    if (typeof freeFields !== 'undefined' && freeFields.length > 0) {
      freeFields.forEach((field, i) => {
        // console.log(`[RideComponent.FreeField]: checking '${freeFields[i].description}' value ->`, this.ride.freeFields[i]);
        const inValid = (
          this.ride.freeFields[i] === '' ||
          (
            this.ride.freeFields[i] === false &&
            this.ride.freeFields[i] !== '' &&
            this.ride.freeFields[i] !== null
          )
        );
        const f = $(`#ff-${i}`);
        if (field.mandatory === 'mandatory') {
          if (inValid) {
            f.addClass('error required');
            errors = true;
          }
        } else if (field.mandatory === 'onaccount_mandatory' && this.ride.paymentMethod === 'oprekening') {
          if (inValid) {
            f.addClass('error required');
            errors = true;
          }
        }
      });
    }

    if(this.editMode !== 'singleRide') {
      if (this.ride.requestedDate === this.returnRide) {
        dateField.addClass('error required');
        errors = true;
      }
    }

    return errors;
  }

  validateForm() {
    let isValid = true;
    for (const key in this.formInputs) {
      if (this.formInputs.hasOwnProperty(key)) {
        if (!this.formInputs[key].valid) {
          isValid = false;
          break;
        }
      }
    }
    this.validForm = isValid;
  }

  customSelectChanged(value: any, fieldName: string) {
    const fieldStatus = fieldName.toLowerCase().replace(/ /g, '-');
    if (this.formInputs[fieldStatus]) {
      if (value === '') {
        this.formInputs[fieldStatus] = new FieldStatus(false, 'please_select_dropdown_value');
      } else {
        this.formInputs[fieldStatus].valid = true;
      }
      this.onImportantValueChange(null);
    }
  }

  getNumberSelectOptions(field: any): any[] {
    const min = field.min;
    const max = field.max;
    const options = [];

    for (let i = min; i <= max; i++) {
      options.push(i);
    }

    return options;
  }

  changeFreeFieldSelectValue(event: any) {
    // console.log(`[RideComponent.changeFreeFieldSelectValue]: event`, event);
    // this.ride.freeFields[i] = value;
  }

  selectPrice(type: string, price: VehiclePrice) {
    this.selectedRideType = type;
    this.selectedPrice = price;
    this.selectedPriceLine = price[type];
    console.log('selectedRideType', type);
    console.log('selectedPrice', price);
    console.log('selectedPriceLine', price[type]);

    this.ride.amount = price[type].amount;
    this.ride.decAmount = price[type].decimalAmount;
    this.ride.vehicleType = price.vehicleTypeId;

    $('#' + this.pricingModalId).prop('checked', false).change();

    $('#base-trip').scrollTop(0);

    // setTimeout(() => {
    //   $('#base-trip').scrollTop($('#base-trip')[0].scrollHeight);
    // }, 500);

    if (type === 'single') {
      this.blockOrderState(false);
    } else {
      this.blockOrderState();
      this.setReturnRideData();
    }
  }

  onImportantValueChange(event: any) {
    // console.log('onImportantValueChange()');
    // console.log('change event', event);
    this.selectedRideType = null;
    this.selectedPrice = null;
    this.selectedPriceLine = null;
    this.validateForm();
  }

  unblockOrderState() {
    this.blockOrderState(false);

    this.selectedPrice = undefined;
    this.selectedPriceLine = undefined;
    this.selectedRideType = '';
  }

  blockOrderState(block: boolean = true) {
    this.orderStateBlocked = block;
    if (block) {
      $('#base-trip').addClass('active-return-trip').addClass('blocked');
      $('#return-trip').addClass('active');
    } else {
      $('#base-trip').removeClass('active-return-trip').removeClass('blocked');
      $('#return-trip').removeClass('active');
    }
  }

  customIsFieldValid(fieldName: string) {
    const input = this.formInputs[fieldName.toLowerCase().replace(/ /g, '-')];
    return input ? input.valid : false;
  }

  customIsFieldValidMessage(fieldName: string) {
    const input = this.formInputs[fieldName.toLowerCase().replace(/ /g, '-')];
    return input ? input.message : '';
  }

  getCustomFieldName(fieldName: string) {
    return fieldName.toLowerCase().replace(/ /g, '-');
  }

  parseISOString(s) {
    const b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }

  initFieldAutocomplete(element, i) {
    let options = element.autoComplete.map((item) => `${item}`);
    $(this.thisElement.nativeElement).find(`#autocomplete-field-${i}`).autocomplete({
      delay: 100,
      minLength: 1,
      source: options,
    });
  }
}
